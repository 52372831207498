.footer-wrapper {
  width: 100%;
  margin-top: 5rem;
  margin-bottom: 1rem;
}
.footer-wrapper > hr {
  width: 100%;
  height: 1px;
  border: none;
  background-color: white;
  margin-top: 1rem;
}
.cfooter {
  display: flex;
  width: 100%;
  justify-content: space-around;
  /* margin-bottom: 5rem; */
}
.clogo {
  display: flex;
  align-items: flex-start;
  gap: 1rem;
}
.clogo > img {
  width: 2.5rem;
  height: 2.5rem;
}
.clogo > span {
  font-weight: 600;
  font-size: 1rem;
}
.block {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  max-width: 12rem;
}
.details {
  display: flex;
  flex-direction: column;
  width: inherit;
  font-size: 0.7rem;
  gap: 1rem;
  font-style: italic;
}
.details > :nth-child(1) {
  font-weight: 500;
  font-style: normal;
  font-size: 1.2rem;
}
.pnglines:hover {
  cursor: pointer;
}
.pnglines {
  display: flex;
  gap: 1rem;
  align-items: center;
}
.icon {
  width: 25px;
}
.copyRight {
  display: flex;
  flex-direction: column;
  width: 100%;
  text-align: center;
  font-size: 0.65rem;
  margin-top: 2rem;
}

@media screen and (max-width: 768px) {
  .cfooter {
    justify-content: flex-start;
    gap: 2rem;
    flex-wrap: wrap;
    align-items: flex-start;
    font-size: 1.7rem;
  }
}

@media screen and (max-width: 640px) {
  .cfooter {
    justify-content: center;
  }
  .clogo {
    display: none;
  }
}
